import { useQuery } from '@tanstack/react-query';
import { getTemplateVersions } from 'services/api';

function useDigitalAssistantTemplateVersions(productUuid, templateId) {
    return useQuery({
        queryKey: ['digitalAssistantTemplateVersions', { productUuid, templateId }],
        queryFn: () => getTemplateVersions(productUuid, templateId),
        enabled: !!productUuid && !!templateId,
    });
}

export default useDigitalAssistantTemplateVersions;
