import { useMemo } from 'react';

import useGetAvailableApplications from 'services/hooks/useGetAvailableApplications';
import { useHeader } from 'services/hooks/useHeader';

export function useCategoriesList() {
    const { accessList, selectedProductUuid } = useHeader();
    const { data: availableApplications } = useGetAvailableApplications();

    if (accessList.length === 0) {
        return [];
    }

    const selectedProductType = accessList.find(({ product }) => product.productId === selectedProductUuid).product
        .type;

    return useMemo(
        () => [
            ...new Set(
                availableApplications
                    .filter((app) => {
                        if (selectedProductType === 'braincube') {
                            return app.type === 'BRAINCUBE_APP';
                        }
                        return ['NPM_REGISTRY', 'CDN'].includes(app.type);
                    })
                    .filter((app) => app.category)
                    .map((app) => app.category)
                    .sort((a, b) => a.localeCompare(b)),
            ),
        ],
        [availableApplications, selectedProductType],
    );
}

export default useCategoriesList;
