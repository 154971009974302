import React from 'react';

import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Stack } from '@mui/material';

import { useI18n } from '@braincube/i18n';
import PropTypes from 'prop-types';

function ConfirmationDialog({ opened, onClose, onConfirm, title, children }) {
    const i18n = useI18n();

    return (
        <Dialog open={opened} fullWidth onClose={onClose} data-testid="confirm-dialog">
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button color="inherit" onClick={onClose} data-testid="confirm-dialog-cancel">
                    {i18n.tc('common.cancel')}
                </Button>
                <Button variant="contained" onClick={onConfirm} data-testid="confirm-dialog-confirm">
                    {i18n.tc('common.confirm')}
                </Button>
            </DialogActions>
        </Dialog>
    );
}

ConfirmationDialog.propTypes = {
    opened: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    onConfirm: PropTypes.func.isRequired,
    title: PropTypes.string.isRequired,
    children: PropTypes.node.isRequired,
};

export default ConfirmationDialog;
