import { Grid, Skeleton } from '@mui/material';
import React from 'react';

function ApplicationsSkeletons() {
    const gridItems = [];

    for (const _ of Array(12)) {
        gridItems.push(
            <Grid item xs={6} sm={4} lg={2}>
                <Skeleton height={150} variant="rectangular" animation="wave" />
            </Grid>,
        );
    }

    return gridItems;
}

export default ApplicationsSkeletons;
