import { useI18n } from '@braincube/i18n';
import { Alert, Button, DialogActions, Typography } from '@mui/material';
import ConfirmationDialog from 'components/ConfirmationDialog';
import { useFormikContext } from 'formik';
import PropTypes from 'prop-types';
import React, { useCallback, useState } from 'react';

function StepperActions({ currentStep, onPrev, onBack, onNext }) {
    const { isValid, handleSubmit, dirty } = useFormikContext();
    const i18n = useI18n();

    const [confirmationDialogOpened, setConfirmationDialogOpened] = useState(false);

    const confirmPrev = useCallback(() => {
        dirty ? setConfirmationDialogOpened(true) : onPrev();
    }, [dirty, onPrev]);

    const closeConfirmationDialog = useCallback(() => {
        setConfirmationDialogOpened(false);
    }, []);

    return (
        <>
            <DialogActions>
                {/* Previous button */}
                <Button
                    variant="contained"
                    onClick={currentStep > 0 ? onBack : confirmPrev}
                    data-testid="stepper-prev"
                    color="secondary"
                >
                    {i18n.tc('dialog.previous')}
                </Button>

                {/* Next or publish button */}
                {currentStep === 0 ? (
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onNext}
                        data-testid="stepper-next"
                        disabled={!isValid}
                    >
                        {i18n.tc('dialog.next')}
                    </Button>
                ) : (
                    <Button
                        onClick={handleSubmit}
                        variant="contained"
                        color="primary"
                        disabled={!isValid}
                        data-testid="stepper-publish"
                    >
                        {i18n.tc('common.publish')}
                    </Button>
                )}
            </DialogActions>
            <ConfirmationDialog
                opened={confirmationDialogOpened}
                onClose={closeConfirmationDialog}
                onConfirm={onPrev}
                title={i18n.tc('digitalAssistantCreator.alert.prevConfirmation.title')}
            >
                <Alert severity="warning">{i18n.tc('digitalAssistantCreator.alert.prevConfirmation.content')}</Alert>
            </ConfirmationDialog>
        </>
    );
}

StepperActions.propTypes = {
    currentStep: PropTypes.number.isRequired,
    onPrev: PropTypes.func.isRequired,
    onBack: PropTypes.func.isRequired,
    onNext: PropTypes.func.isRequired,
};

export default StepperActions;
