import { fetchWithBearer, fetchWithProductBearer } from '@braincube/jwt-browser';
import { getSsoPage } from 'services/helpers';
import { DA_template_mock } from './constantMock';

export async function getUsers() {
    const response = await fetchWithBearer(`https://${getSsoPage()}/sso-server/api/v2/user`);

    if (response.ok) {
        const data = await response.json();
        return data.users;
    }
    return Promise.reject();
}

export async function getApplications(productUuid) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productUuid}/apps`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getCustomApplication(productUuid) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productUuid}/apps/custom`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getApplicationVersions(applicationId) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${applicationId}/version`,
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getApplicationPermissions(appId, productUuid) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${appId}/products/${productUuid}/share`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function updateApplicationPermissions(appId, productUuid, share) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/apps/${appId}/products/${productUuid}/share`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
                'Content-Type': 'application/json',
            }),
            method: 'PUT',
            body: JSON.stringify(share),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getOffers(productUuid) {
    const response = await fetchWithBearer(`https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/offers`, {
        headers: new Headers({
            'x-product-id': productUuid,
        }),
    });

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getProductInfo(productUuid) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productUuid}/info`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function updateProductApplication(productUuid, body) {
    const response = await fetchWithBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager}/api/products/${productUuid}/apply`,
        {
            method: 'POST',
            headers: new Headers({
                'x-product-id': productUuid,
                'Content-Type': 'application/json',
            }),
            body: JSON.stringify(body),
        },
    );

    if (response.ok) {
        return Promise.resolve();
    }
    return Promise.reject();
}

export async function getDigitalAssistantTemplates(productUuid) {
    const response = await fetchWithProductBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager.replace('appsmanager', 'danm')}/danm-api/templates`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getDigitalAssistantTemplate(productUuid, digitalAssistantTemplateId) {
    const response = await fetchWithProductBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager.replace('appsmanager', 'danm')}/danm-api/templates/${digitalAssistantTemplateId}`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getInstalledDigitalAssistants(productUuid) {
    const response = await fetchWithProductBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager.replace('appsmanager', 'danm')}/danm-api/digital-assistants`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );
    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getDigitalAssistant(productUuid, digitalAssistantId) {
    const response = await fetchWithProductBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager.replace('appsmanager', 'danm')}/danm-api/digital-assistants/${digitalAssistantId}`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function deleteDigitalAssistant(productUuid, digitalAssistantVersionId) {
    const response = await fetchWithProductBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager.replace('appsmanager', 'danm')}/danm-api/digital-assistants/${digitalAssistantVersionId}/uninstall`,
        {
            method: 'DELETE',
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return Promise.resolve();
    }
    return Promise.reject();
}

export async function getTemplateVersionById(productUuid, templateVersionId) {
    const response = await fetchWithProductBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager.replace('appsmanager', 'danm')}/danm-api/templates/versions/${templateVersionId}`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}

export async function getTemplateVersions(productUuid, templateId) {
    const response = await fetchWithProductBearer(
        `https://${window.BC_API_ENDPOINTS_CONF.appsManager.replace('appsmanager', 'danm')}/danm-api/templates/${templateId}/versions`,
        {
            headers: new Headers({
                'x-product-id': productUuid,
            }),
        },
    );

    if (response.ok) {
        return response.json();
    }
    return Promise.reject();
}
