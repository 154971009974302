import { useMemo } from 'react';

import useApplications from 'services/hooks/react-query/useApplications';
import useCustomApplications from 'services/hooks/react-query/useCustomApplications';
import { useHeader } from 'services/hooks/useHeader';

export default function useGetAvailableApplications() {
    const { selectedProductUuid } = useHeader();
    const { data: applications, isLoading: isLoadingApps } = useApplications(selectedProductUuid);
    const { data: customApplications, isLoading: isLoadingCustomApps } = useCustomApplications(selectedProductUuid);

    const isLoading = useMemo(() => isLoadingApps || isLoadingCustomApps, [isLoadingApps, isLoadingCustomApps]);

    const data = useMemo(() => {
        if (!applications) {
            return [];
        }
        const appCustomIds = customApplications?.map((app) => app.id);
        if (!appCustomIds) {
            return applications;
        }

        return applications.map((app) => {
            return {
                ...app,
                custom: appCustomIds.includes(app.id),
            };
        });
    }, [applications, customApplications]);

    return {
        data: data,
        isLoading: isLoading,
    };
}
