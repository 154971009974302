import { Box, Card, CardActionArea, CardContent, Grid, Typography, styled, useTheme } from '@mui/material';
import DigitalAssistantCreator from 'components/App/components/AppHeader/components/DigitalAssistantCreator';
import { DigitalAssistantCreatorContextProvider } from 'components/App/components/AppHeader/components/DigitalAssistantCreator/context';
import { ReactComponent as InstallIcon } from 'images/install.svg';
import React, { useState, useCallback } from 'react';
import { getAppIcon } from 'services/helpers';
import useDigitalAssistantTemplateVersions from 'services/hooks/react-query/useDigitalAssistantTemplateVersions';
import { useHeader } from 'services/hooks/useHeader';

// TODO: Manage in sx?
const StyledCard = styled(Card)(({ theme }) => ({
    position: 'relative',
    '&:hover': {
        '& .quick-actions': {
            opacity: 1,
        },
    },
    height: '100%',
    marginBottom: theme.spacing(1),
}));

// TODO: Manage in sx?
const StyledQuickActions = styled('div')({
    display: 'block',
    top: 0,
    right: 0,
    position: 'absolute',
    width: 45,
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.4)',
    transition: 'ease-in 0.2s',
    opacity: 0,
});

// TODO: Manage in sx?
const StyledActionInstall = styled('div')(({ theme }) => ({
    width: 45,
    height: 45,
    cursor: 'pointer',
    backgroundColor: theme.palette.common.black,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
}));

function AvailableDigitalAssistant({ digitalAssistant }) {
    const theme = useTheme();
    const [openDigitalAssistantCreator, setOpenDigitalAssistanceCreator] = useState(false);

    const { selectedProductUuid } = useHeader();
    const { data: allTemplateVersions } = useDigitalAssistantTemplateVersions(
        selectedProductUuid,
        digitalAssistant?.id,
    );

    const handleInstallation = useCallback((event) => {
        event.stopPropagation();
        event.preventDefault();

        // open la dialogue d'installation du DA
        setOpenDigitalAssistanceCreator(true);
    }, []);

    return (
        <Grid item xs={6} sm={4} lg={2}>
            <StyledCard>
                <CardActionArea
                    sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'flex-start',
                    }}
                >
                    <Box
                        sx={{
                            width: '100%',
                            height: 120,
                            fontSize: 60,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            bgcolor: digitalAssistant.color,
                        }}
                    >
                        {getAppIcon(digitalAssistant.icon, theme.palette.getContrastText(digitalAssistant.color))}
                    </Box>
                    <CardContent>
                        <Typography gutterBottom variant="subtitle2">
                            {digitalAssistant.name}
                        </Typography>
                        <Typography variant="caption">{digitalAssistant.description}</Typography>
                    </CardContent>
                </CardActionArea>
                <StyledQuickActions className="quick-actions">
                    <StyledActionInstall tabIndex={0} onClick={handleInstallation}>
                        <InstallIcon width={25} />
                    </StyledActionInstall>
                </StyledQuickActions>
            </StyledCard>
            <DigitalAssistantCreatorContextProvider>
                {openDigitalAssistantCreator && allTemplateVersions && (
                    <DigitalAssistantCreator
                        selectedDigitalAssistant={allTemplateVersions?.at(0)}
                        opened={openDigitalAssistantCreator}
                        onClose={() => setOpenDigitalAssistanceCreator(false)}
                    />
                )}
            </DigitalAssistantCreatorContextProvider>
        </Grid>
    );
}

export default AvailableDigitalAssistant;
